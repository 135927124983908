import React from 'react';
import { navigate } from 'gatsby';
import { DEFAULT_LANGUAGE } from '../config/const';
import { useEffect } from 'react';
import { getRedirectLanguage } from '../utils/get-current-language-data';

const IndexPage: React.FC<{}> = () => {
  useEffect(() => {
    const preferredLocale = getRedirectLanguage();
    navigate(`/${preferredLocale}`, { replace: true });
  }, []);
  return null;
};
export default IndexPage;
