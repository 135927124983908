import { Group } from '../types';
import { usePageDataContext } from '../hooks/use-page-context';
import { DEFAULT_LANGUAGE, LANGUAGES } from '../config/const';

export const getCurrentLanguage = () => {
  const { lang } = usePageDataContext();
  return lang || DEFAULT_LANGUAGE.lang;
};

export function getCurrentLanguageData<T>(groups: Group<T>[]): T {
  const currentLanguage = getCurrentLanguage();
  return groups.find(group => group.fieldValue === currentLanguage).nodes[0]
    .data;
}

export const getRedirectLanguage = () => {
  if (typeof navigator === `undefined`) {
    return DEFAULT_LANGUAGE.locale;
  }

  const uaLanguage = (navigator && navigator.language) || '';

  if (!uaLanguage) {
    return DEFAULT_LANGUAGE.locale;
  }

  const languages = LANGUAGES.filter(l => l.matcher).filter(l =>
    l.matcher(uaLanguage)
  );

  if (languages[0]) {
    return languages[0].locale;
  }

  return DEFAULT_LANGUAGE.locale;
};
